<template>
  <div>
    <page-header icon="fa fa-dice" :title="title" :links="getLinks()"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName) && canView">
      <b-row>
        <b-col>
          <div ref="exportMe">
            <x-table
              id="articles"
              :columns="columns"
              :items="items"
              :show-counter="true"
              verbose-name="exemplaire"
              @selectionChanged="onSelectionChanged($event)"
            >
            </x-table>
          </div>
        </b-col>
      </b-row>
    </div>
    <print-labels-modal
      @save="printLabels"
      modalId="printItemsLabelsModal"
    ></print-labels-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeStoreArticle, makeStoreItem } from '@/types/store'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import PrintLabelsModal from '@/components/Modals/PrintLabelsModal.vue'

export default {
  name: 'StoreItems',
  mixins: [BackendMixin],
  components: {
    PrintLabelsModal,
    PageHeader,
    LoadingGif,
    XTable,
  },
  data() {
    return {
      loadingName: 'store-items',
      items: [],
      columns: [],
      selection: [],
    }
  },
  mounted() {
    this.columns = this.getColumns()
    this.loadStoreItems()
  },
  watch: {
  },
  computed: {
    canView() {
      return this.hasPerm('store.view_item')
    },
    title() {
      return 'Inventaire Ludothèque'
    },
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      const columns = [
        { selector: true, 'name': 'selector', maxWidth: '20px', },
        {
          name: 'name',
          label: 'Nom',
          onClick: item => {
            router.push(
              { name: 'store-article-detail', params: { articleId: '' + item.id, }, }
            )
          },
        },
        {
          name: 'classification',
          label: 'Code ESAR',
        },
        {
          name: 'type',
          label: 'Type',
        },
        {
          name: 'reference',
          label: 'Référence',
        },
        {
          name: 'location',
          label: 'Emplacement',
        },
        {
          name: 'status',
          label: 'État',
        },
        {
          name: 'isAvailable',
          label: 'Disponible',
        },
        {
          name: 'isBorrowed',
          label: 'Emprunt en cours',
        }
      ]
      return columns
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'liste-' + slugify('Ludothèque')
      const docContent = this.$refs.exportMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'liste-' + slugify('Ludothèque')
      const docContent = this.$refs.exportMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const loading = this.isLoading(this.loadingName)
      const noSelection = this.selection.length === 0
      return [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'far fa-file-excel',
          cssClass: loading ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: loading ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 3,
          label: 'Étiquettes',
          callback: this.labelsMe,
          icon: 'fa fa-tag',
          cssClass: (loading || noSelection) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async loadStoreItems() {
      this.startLoading(this.loadingName)
      const backendApi = new BackendApi('get', '/store/api/items/')
      try {
        let resp = await backendApi.callApi()
        this.items = resp.data.map(this.makeItem)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    makeItem(elt) {
      const item = makeStoreItem(elt)
      const article = makeStoreArticle(elt.article)
      const isBorrowed = elt['is_borrowed'] ? 'Oui' : 'Non'
      const isAvailable = item.isAvailable ? 'Oui' : 'Non'
      const hasItemLocation = item.location && item.location.id
      let location = article.location
      if (hasItemLocation) {
        location = item.location
      }
      return {
        id: item.id,
        name: article.name,
        reference: item.reference,
        location: location.name,
        isBorrowed: isBorrowed,
        isAvailable: isAvailable,
        status: item.status,
        classification: article.classification.name,
        type: article.type.name,
        article: article,
      }
    },
    labelsMe() {
      this.$bvModal.show('printItemsLabelsModal')
    },
    async printLabels(template) {
      const docUrl = '/store/store-items-labels/<key>/pdf/'
      const docSlug = 'store-items'
      const data = {
        items: this.selection.map(itm => '' + itm.id).join('-'),
        'labels_template': template.labelsTemplate.id,
        'empty_labels': template.emptyLabels,
      }
      const docContent = JSON.stringify(data)
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onSelectionChanged(event) {
      this.selection = event.items
    },
  },
}
</script>

<style scoped lang="less">
</style>
